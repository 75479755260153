



.location-card #button {

  margin-top: 4px;
  color: #282c34

}
.location-card-row {

  background-color: #FFF;
  margin-left: 4px;
  width: 100%;
  justify-content: center;
  max-height: 10%;
  border-radius: 12px;
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: transform 0.10s ease-in-out;
  -moz-transition:transform 0.10s ease-in-out;

  padding: 15px;

}
.location-card-row:hover  {
  transform: scale(1.05);
}
.location-card {
  margin: 20px 0 20px 0 ;
  font-size: 15px;

}
.Location {
  margin-top: 5px;
}
.Address {
  margin-top: 5px;
}
a {
  text-decoration: none;

}
.helper-text{
  color: #828282;
  font-size: 12px;
  margin-top: 15px!important;
}
